import React from 'react'
import { PageWrapper } from '~components/Core'
// import HeaderButton from '~sections/digital/Header'
// import PrivacyPolicy from "~sections/utility/Privacy"
import TermsConditionSurvei from '~sections/utility/TermsConditionSurvei'
import FooterSix from '~sections/Home/footerSix'
// import { Link } from '~components'
import { graphql } from 'gatsby'

const header = {
  headerClasses:
    'site-header site-header--menu-end dark-header site-header--sticky',
  containerFluid: false,
  darkLogo: false,
}

export default function TermsConditionsSurvey() {
  return (
    <PageWrapper headerConfig={header}>
      {/* <PrivacyPolicy /> */}
      <TermsConditionSurvei />
      <FooterSix />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
